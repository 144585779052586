import React from "react"
import { Link, graphql } from "gatsby"
// import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import IconArrow from "../components/iconArrow"

const Entry = ({ data }) => (
  <Layout>
    <SEO title="Entry" />
    <div className="entry">
      <div className="entry__meta">
        <p className="entry__meta-item">
          {data.allAirtable.edges[0].node.data.date}
        </p>
        <Link
          to={`/${data.allAirtable.edges[0].node.data.location[0].id}`}
          className="entry__meta-item"
        >
          {data.allAirtable.edges[0].node.data.location
            ? data.allAirtable.edges[0].node.data.location[0].data.locationName
            : ""}
          <IconArrow className="entrycard__icon" />
        </Link>
        {data.allAirtable.edges[0].node.data.photographer.map(({ data, id }) => {
          return (
            <Link to={`/${id}`} className="entry__meta-item" key={id}>
              {data.photographerName}
              <IconArrow className="entrycard__icon" />
            </Link>
          )
        })}
      </div>
      <figure className="entry__image">
        {data.allAirtable.edges[0].node.data.images ? (
          <img
            alt={
              data.allAirtable.edges[0].node.data.photographer
                ? data.allAirtable.edges[0].node.data.photographer[0].data
                    .photographerName +
                  ", " +
                  data.allAirtable.edges[0].node.data.date
                : "No Picture"
            }
            src={
              data.allAirtable.edges[0].node.data.images
                ? data.allAirtable.edges[0].node.data.images.localFiles[0].url
                : ""
            }
          />
        ) : (
          ""
        )}
      </figure>
    </div>
  </Layout>
)

export const query = graphql`
  query entryQuery($slug: String) {
    allAirtable(filter: { id: { eq: $slug } }) {
      edges {
        node {
          id
          data {
            date(formatString: "DD.MM.YYYY")
            location {
              id
              data {
                locationName
              }
            }
            photographer {
              id
              data {
                photographerName
              }
            }
            images {
              localFiles {
                url
              }
            }
          }
        }
      }
    }
  }
`

export default Entry
